<template>
  <div
    class="bg-indigo-200 dark:bg-indigo-600 dark:text-white rounded-lg px-4 pb-8"
  >
    <div v-html="$store.mdt('#DOUM_H1')"></div>

    <div class="flex justify-start items-center my-4 space-x-4">
      <button
        class="rounded-full border-2 border-black dark:border-white px-4 py-1 text-sm hover:bg-black hover:text-white"
        @click="onWorkspace('rtz')"
        v-html="$store.mdt('#DOUM_ENTER')"
      ></button>
      <div class="text-xs" v-html="$store.mdt(loading_msg)"></div>
    </div>

    <div v-html="$store.mdt('#DOUM_MSG_1')"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading_msg: "",
    };
  },
  mounted() {
    console.log(
      "process.env.VUE_APP_KLAYTN_NETWORK_ID",
      process.env.VUE_APP_KLAYTN_NETWORK_ID
    );
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
  methods: {
    async onWorkspace(_name) {
      console.log("onWorkspace", _name);

      // this.$store.commitRoot("set_enter_name", {
      //   target: `/${_name}work`,
      //   fail: "/doum",
      //   condition: () => {
      //     const ret = klaytn.selectedAddress === addr;
      //     console.log("condition", klaytn.selectedAddress, addr);
      //     return ret;
      //   },
      // });

      // const klaytn = window.klaytn;
      // let accounts = undefined;
      // const old_loading = this.loading_msg;
      // this.loading_msg = "#LOADING_WAIT_WALLET";
      // try {
      //   accounts = await klaytn.enable();
      // } catch (err) {
      //   err;
      // }
      // this.loading_msg = old_loading;
      // console.log("klaytn.enable(), accounts", accounts);

      // let addr = null;
      // try {
      //   if (!Array.isArray(accounts) || accounts.length < 1) {
      //     this.$store.Alert("#ERR_NETWORK");
      //     return;
      //   }

      //   let wait_time = 0;
      //   while (wait_time < 9999) {
      //     wait_time += 300;
      //     await this.$store.waitAsync(300);

      //     if (klaytn.selectedAddress != null) {
      //       break;
      //     }
      //   }

      //   if (typeof klaytn.selectedAddress != "string") {
      //     this.$store.Alert("#ERR_NETWORK");
      //     return;
      //   }

      //   addr = klaytn.selectedAddress;
      // } catch (err) {
      //   this.$store.Alert("#ERR_NETWORK");
      //   return;
      // }

      // console.log("addr", addr);
      // if (!this.$store.isRtzAddr(addr)) {
      //   console.log("addr check fail", addr);
      //   this.$store.Alert("#ERR_DOUM_DESIGNER_ONLY");
      //   return;
      // }

      // this.$store.commitRoot("set_klaytn_logined", true);
      this.$router.push(`/${_name}work`);
    },
  },
};
</script>
